.main-hero {
    position: relative;
    padding-top: 194px;
    padding-bottom: 64px;
    // background-color: $black-300;
    background-color: #000000;
    border-radius: 0px 0px 16px 16px;
    height: auto;
    min-height: 100vh;

    @include media-breakpoint-down(md) {
        padding-top: 128px;
        padding-bottom: 56px;
    }

    &-bcg {
        position: absolute;

        &.one {
            left: 0;
            bottom: 0;
            width: 54%;

            @include media-breakpoint-down(sm) {
                left: 0;
                bottom: 0;
                width: 100%;
            }
        }

        &.second {
            right: 35%;
            bottom: 45%;

            @include media-breakpoint-down(sm) {
                right: 60%;
                bottom: 40%;
                transform: translate(50%, 50%);
            }
        }

        img {
            height: auto;
            width: 100%;
        }
    }

    &-wrap {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        position: relative;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-content {
        max-width: 600px;

        @include media-breakpoint-down(md) {
            margin-bottom: 32px;
            max-width: initial;
        }

        h1 {
            font-size: 64px;
            color: $white;
            line-height: 71px;
            font-weight: 300;
            color: $white;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                font-weight: 300;
                line-height: 33px;
            }

            span {
                font-weight: 600;
                color: $light-green;
            }
        }

        p {
            font-weight: 400;
            color: $white;
            font-size: 20px;
            line-height: 30px;
            margin-top: 24px;
            max-width: 360px;

            @include media-breakpoint-down(sm) {
                font-size: 14px;
                line-height: 21px;
                max-width: initial;
            }
        }

        &-link {
            margin-top: 32px;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            color: $white;

            &-arrow {
                width: 42px;
                height: 42px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: $light-green;
                border-radius: 50%;
                margin-right: 10px;
            }
        }
    }

    &-img {
        position: relative;
        margin-right: calc(-50vw + 50%);
        position: relative;

        @include media-breakpoint-down(md) {
            // margin: 0;
            align-self: flex-end;
        }

        &.circle {
            &::after {
                content: "";
                width: 50vw;
                height: 50vw;
                border-radius: 50%;
                background: linear-gradient(179.97deg, rgba(255, 255, 255, 0) -18.04%, rgba(255, 255, 255, 0.04) 99.98%);
                position: absolute;
                right: -50%;
                top: -20%;
                z-index: 0;
            }
        }
    }
}