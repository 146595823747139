.app-block {
    margin-bottom: 120px;

    @include media-breakpoint-down(md) {
        margin-bottom: 96px;
    }

    .container {
        position: relative;
        align-items: center;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &-text {
        padding-left: 32px;
        max-width: 50%;
        width: 100%;

        @include media-breakpoint-down(md) {
            padding-left: 0;
            max-width: 100%;
        }

        .app-list-item {
            flex-direction: column;

            &-text {
                margin-left: 0px;
                margin-top: 20px;

                h4 {
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
    }

    &-img {
        max-width: 50%;
        width: 100%;

        @include media-breakpoint-down(md) {
            max-width: 100%;
            width: 70%;
            margin: 0 auto !important;
            margin-bottom: 40px !important;
            order: -1;
        }

        @include media-breakpoint-down(sm) {
            width: 100%;
        }

        &.left {
            margin-left: -64px;
        }
        
        &.right {
            margin-right: -64px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }
}