.app-pricing {
    position: relative;

    .small-hero {
        min-height: auto;
        padding-bottom: 200px;
        margin-bottom: 0;
        border-radius: 0px 0px 16px 16px;

        @include media-breakpoint-down(sm) {
            min-height: 98vh;
            padding: 0;
            display: flex;
            align-items: center;
        }

        &.circles {
            &::after {
                width: 230px;
                height: 230px;
                bottom: -80px;
                left: -80px;
            }
            
            &::before {
                @include media-breakpoint-down(sm) {
                    background: linear-gradient(179.97deg, rgba(255, 255, 255, 0) -18.04%, rgba(255, 255, 255, 0.14) 99.98%);
                    top: -10%;
                    right: -20%;
                }
            }
        }

        h1 {
            font-size: 48px;
            line-height: 55px;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                line-height: 34px;
            }
        }
    }

    &-wrap {
        margin: 104px 0px 130px;

        @include media-breakpoint-down(sm) {
            margin: 96px 0px 80px;
        }
    }

    h2 {
        font-weight: 800;
        font-size: 30px;
        line-height: 36px;
        color: $black-600;
    }

    &-included {
        margin-top: 32px;
        display: flex;
        align-items: center;

        &::after {
            content: '';
            position: relative;
            width: 100%;
            height: 2px;
            border-radius: 3px;
            background-color: $gray-100;
            margin-left: 16px;
        }

        span {
            white-space: nowrap;
            text-transform: uppercase;
        }

        .black {
            color: $black-700;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.05em;
            font-weight: 600;
        }

        .green {
            color: $light-green-200;
            font-size: 14px;
            line-height: 20px;
            font-family: 'Inter', sans-serif;
            font-weight: 600;
        }

    }

    &-benefits {
        width: 100%;

        &-list {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;

            @include media-breakpoint-down(md) {
                flex-wrap: wrap;
            }

            .app-includes-list {
                @include media-breakpoint-down(lg) {
                    max-width: calc(33% - 24px);
                }

                @include media-breakpoint-down(md) {
                    max-width: calc(50% - 24px);
                }

                @include media-breakpoint-down(sm) {
                    max-width: 100%;
                }
            }
        }
    }

    &-tariffs {
        &-card {
            &-lists {
                display: flex;
                justify-content: flex-start;

                @include media-breakpoint-down(sm) {
                    flex-wrap: wrap;
                }
                
                .app-includes-list {
                    margin-right: 32px;
                }
            }
        }

        .mt-72 {
            margin-top: 72px;
        }
    }
}