.app-contact {
    position: relative;

    &-wrap {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        position: relative;

        @include media-breakpoint-down(sm) {
            min-height: 60vh;
        }

        .app-button {
            align-self: flex-end;
            font-weight: 600;

            @include media-breakpoint-down(sm) {
                align-self: flex-start;
            }
        }
    }

    &-info {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        h3 {
            color: $white;
            font-weight: 600;
            font-size: 30px;
            line-height: 36px;
            margin-bottom: 12px;

            @include media-breakpoint-down(lg) {
                font-size: 24px;
            }
        }

        p, span {
            color: $gray-500;
        }

        p {
            font-size: 18px;
            line-height: 24px;
            font-weight: 400;
            margin-bottom: 32px;

            @include media-breakpoint-down(lg) {
                font-size: 16px;
            }

            @include media-breakpoint-down(sm) {
                margin-bottom: 16px;
            }
        }

        span {
            font-size: 16px;
            line-height: 24px;
        }
        
        &-social {
            margin-top: 24px;
            display: flex;
            flex-direction: column;

            &-item {
                display: flex;
                margin-bottom: 12px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    display: flex;
                }

                &-icon {
                    width: 100%;
                    max-width: 24px;
                    margin-right: 12px;
                    flex-shrink: 0;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                p, a {
                    color: $gray-500;
                    word-break: break-word;
                    margin: 0;
                }
            }
        }
    }

    &-inputs {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .app-input {
            margin-bottom: 32px;
        }
    }
}