.app-section.black-rounded {
    position: relative;            
    --round-height: 11.58vw;
    margin-top: 260px;
    margin-bottom: calc(0px - var(--round-height));
    
    @include media-breakpoint-down(sm) {
        margin-top: 200px;
    }

    .black-rounded {

        // Top and bottom circles
        &__circle {
            display: block;
            position: relative;
            overflow: hidden;
            z-index: 1;

            // +1px to remove white line of space 
            height: calc(var(--round-height) + 1px); 
            width: 100%;
            
            &::before {
                content: "";
                display: block;
                width: 227.8vw;
                height: 227.8vw;
                border-radius: 50%;
                background-color: $black-800;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
            }
            
            &.black-rounded__circle_top::before {
                top: 0;
            }
            
            &.black-rounded__circle_bottom::before {
                bottom: 0;
                background-image: url(../../../assets/images/teleanalytix/bcg-icons-black.svg);
                background-size: 20%;
                background-repeat: repeat;
                background-position-y: 37.1vw;
            }
        }
        
        &__wrap {
            overflow: hidden;
            position: relative;
            background-color: $black-800;

            @include media-breakpoint-down(sm) {
                padding-top: 80px;
            }
        }
        
        &__content {            
            z-index: 1;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .app-title {
                margin-bottom: 0;
                
                &__text {
                    font-weight: 500;
                }
            }
        }

        &__logo {
            @include media-breakpoint-down(sm) {
                max-width: 100%;
                margin: 0 auto;

                img {
                    width: 100%;
                    height: auto;
                }
            }
        }

        &__steps {
            display: flex;
            flex-direction: column;
            justify-content: center;

            * {
                text-align: center;
            }
        }

        &__step {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            position: relative;

            &::before {
                content: "";
                width: 2px;
                height: 92px;
                background: linear-gradient(180deg, rgba(71, 85, 105, 0.00) 1.09%, #475569 48.43%, rgba(71, 85, 105, 0.00) 98.91%);
                display: block;
            
                @include media-breakpoint-down(sm) {
                    height: 90px;
                }
            }

            &-img {
                width: desktop_vw_min(410px);
                height: desktop_vw_min(410px);
                position: relative;
                margin-top: 16px;

                @include media-breakpoint-down(sm) {
                    width: 278px;
                    height: 278px;
                    margin-left: -50vw;
                    margin-right: -50vw;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            &-content {
                margin-top: 40px;
                white-space: nowrap;

                h3 {
                    font-size: 36px;
                    line-height: 111%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: map-get($teleanalytixai-colors, 'gray-500' );
                    font-weight: 300;

                    @include media-breakpoint-down(sm) {
                        font-size: 30px;
                    }
                    
                    b {
                        color: map-get($teleanalytixai-colors, 'gray-300' );
                        font-weight: 600;
                        margin-top: 8px;
                    }
                }
            }

            &.black-rounded__step_big .black-rounded__step {
                &-img {
                    width: desktop_vw_min(880px);
                    height: desktop_vw_min(880px);
                    margin-top: 30px;                    
                
                    @include media-breakpoint-down(sm) {
                        width: 458px;
                        height: 458px;                        
                    }
                }

                &-content {
                    position: absolute;
                    top: 63%;
                    left: 50%;
                    transform: translateX(-50%);

                    h3 {
                        font-size: 48px;
                        color: map-get($teleanalytixai-colors, 'purple-300' );
                        
                        @include media-breakpoint-down(lg) {
                            font-size: 30px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 24px;
                        }

                        b {
                            color: map-get($teleanalytixai-colors, 'purple-50' );
                        }
                    }
                }
            }
        }

        &__info {
            margin-top: 120px;
            max-width: 560px;

            * {
                text-align: center;
            }

            p {
                margin-top: 16px;
                font-size: 20px;
                line-height: 140%;
                font-weight: 400;
                color: map-get($teleanalytixai-colors, 'gray-200' );
                
                strong {
                    font-weight: 400;
                    color: map-get($teleanalytixai-colors, 'green-300' );

                    b {
                        font-weight: 700;
                    }
                }
            }
        }
    
        &__messages {
            margin-top: 210px;
            position: relative;
            padding: 230px 0px 112px;

            @include media-breakpoint-down(sm) {
                margin-top: 174px;
                padding: 142px 0px 114px;
            }

            &::before {
                content: "";
                width: 227.8vw;
                height: 227.8vw;
                border-radius: 50%;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                // width: 100vw;
                background-image: url(../../../assets/images/teleanalytix/bcg-icons-black.svg);
                background-size: 20%;
                background-repeat: repeat;
            }

            &-wrap {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                
                @include media-breakpoint-up(md) {
                    padding: 0px 144px;
                }
            }

            &-item {
                flex-basis: 100%;
                display: flex;
                align-items: flex-end;
                margin-top: 32px;

                @include media-breakpoint-down(sm) {
                    margin-top: 24px;
                }

                &-text {
                    padding: 40px;
                    border-radius: 24px;
                    max-width: 774px;
                    position: relative;

                    @include media-breakpoint-down(md) {
                        padding: 32px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 24px;
                    }

                    &::before, &::after {
                        content: "";
                        position: absolute;
                        display: block;
                        height: 45px;
                        width: 50px;
                        bottom: 2px;
                        transform: rotate(3deg);
                        clip-path: polygon(79% 0%, 100% 87%, 89% 94%, 76% 97%, 59% 99%, 42% 99%, 25% 96%, 39% 80%, 50% 65%, 62% 43%, 70% 25%);
                    
                        @include media-breakpoint-down(md) {
                            width: 33px;
                            height: 28px;
                        }
                    }
                    
                    &::before {
                        transform: rotate(4deg);
                        bottom: 0px;
                        height: 49px;
                        width: 54px;

                        @include media-breakpoint-down(md) {
                            width: 37px;
                            height: 32px;
                        }
                    }

                    * {
                        font-family: 'Inter', sans-serif;
                        color: map-get($teleanalytixai-colors, 'gray-200' );
                        font-size: 30px;
                        line-height: 144%;
                        font-weight: 400;

                        @include media-breakpoint-down(md) {
                            font-size: 20px;
                        }

                        @include media-breakpoint-down(sm) {
                            font-size: 18px;
                        }

                        b {
                            font-weight: 600;
                        }
                    }
                }

                &-icon {
                    margin-right: 43px;
                    margin-bottom: 20px;
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                    border: 7.5px solid rgba(map-get($teleanalytixai-colors, 'purple-400' ), .08);
                    overflow: hidden;
                    flex-shrink: 0;

                    @include media-breakpoint-down(sm) {
                        display: none;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                &.black-rounded__messages-item_outgoing {
                    justify-content: flex-end;

                    @include media-breakpoint-down(sm) {
                        padding-right: 10px;
                    }

                    .black-rounded__messages-item-text {
                        background-color: map-get($teleanalytixai-colors, 'gray-800' );
                        border: 2px solid map-get($teleanalytixai-colors, 'gray-700' );

                        &::before {
                            transform: scale(-1, 1) rotate(4deg);
                            left: calc(100% - 10px);
                            background-color: map-get($teleanalytixai-colors, 'gray-700' );
                            
                            @include media-breakpoint-down(md) {
                                left: calc(100% - 8px);
                            }
                        }
                        
                        &::after {
                            transform: scale(-1, 1) rotate(3deg);
                            left: calc(100% - 11px);
                            background-color: map-get($teleanalytixai-colors, 'gray-800' );
                            
                            @include media-breakpoint-down(md) {
                                transform: scale(-1, 1);
                                left: calc(100% - 8px);
                            }
                        }                    
                    }
                }
                &.black-rounded__messages-item_incoming {
                    @include media-breakpoint-down(sm) {
                        padding-left: 10px;
                    }

                    .black-rounded__messages-item-text {
                        background-color: map-get($teleanalytixai-colors, 'purple-700' );
                        border: 2px solid map-get($teleanalytixai-colors, 'purple-600' );
                        
                        &::before {
                            right: calc(100% - 10px);
                            background-color: map-get($teleanalytixai-colors, 'purple-600' );
                            
                            @include media-breakpoint-down(md) {
                                right: calc(100% - 8px);
                            }
                        }
                        &::after {
                            right: calc(100% - 11px);
                            background-color: map-get($teleanalytixai-colors, 'purple-700' );
                            
                            @include media-breakpoint-down(md) {
                                right: calc(100% - 8px);
                            }
                        }
                    }
                }
            }
        }
    }
}