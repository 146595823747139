.app-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 560px;
    width: 100%;
    
    &-item {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        margin-bottom: 56px;

        @include media-breakpoint-down(sm) {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &:last-child {
            margin-bottom: 0;
        }

        &-img {
            width: 64px;
            min-width: 64px;
            height: 64px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include media-breakpoint-down(sm) {
                min-width: 40px;
                width: 40px;
                height: 40px;
            }

            img {
                width: 30px;
                height: auto;

                @include media-breakpoint-down(sm) {
                    width: 19px;
                }
            }

            &.purple {
                background-color: $purple;
            }
            &.light-green {
                background-color: $light-green;
            }
            &.black-100 {
                background-color: $black-100;
            }
        }

        &-text {
            margin-left: 20px;

            h4 {
                font-size: 24px;
                line-height: 36px;
                font-weight: 600;
                margin-bottom: 16px;

                @include media-breakpoint-down(sm) {
                    font-size: 20px;
                    line-height: 30px;
                }
            }

            p {
                font-size: 16px;
                line-height: 24px;
                font-weight: 400;
                color: $black-200;

                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                    line-height: 21px;
                }
            }
        }
    }
}