.app-cloud {
    
    .main-hero {
        padding-top: 250px;
        overflow-y: clip;
        
        @include media-breakpoint-down(lg) {
            padding-top: 200px;
        }
        
        &::after {
            content: "";
            width: 65vw;
            height: 200vh;
            position: absolute;
            top: -40vh;
            right: -11vw;
            transform: rotate(25deg);
            opacity: .1;
            background-color: $white-transparent;
            
            @include media-breakpoint-down(lg) {
                width: 100vw;
                top: -10vw;
                right: -50vw;
                transform: rotate(15deg);
            }

            @include media-breakpoint-down(sm) {
                right: -20vw;
            }
        }
        
        &-content {
            @include media-breakpoint-down(md) {
                margin-bottom: 0;
            }
        }
        
        &-wrap {
            align-items: flex-start;
        }
        
        &-bcg {
            &.second {
                right: 30%;
                bottom: 35%;
                width: 20%;
                max-width: 302px;
                
                @include media-breakpoint-down(md) {
                    width: 30%;
                    right: 40px;
                }
                
                @include media-breakpoint-down(sm) {
                    width: 40%;
                    right: 60px;
                    bottom: 65vh;
                }
            }
            
            &.one {
                @include media-breakpoint-down(md) {
                    transform: rotate(35deg);
                    bottom: 130px;
                }

                @include media-breakpoint-down(sm) {
                    bottom: 70px;
                }
            }
        }
    }
    
    &-hero {
        &-about {
            position: relative;
            z-index: 2;
            align-self: flex-end;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 20%;
            
            @include media-breakpoint-down(md) {
                align-self: flex-start;
                margin-top: 100px;
            }
            
            p {
                color: $gray-500;
                font-size: 18px;
                font-weight: 500;
                
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
            
            span {
                position: relative;
                font-weight: 600;
                color: $white;
                font-size: 18px;
                
                @include media-breakpoint-down(sm) {
                    font-size: 14px;
                }
            }
            
            &-block {
                position: relative;
                margin-top: 32px;

                @include media-breakpoint-down(sm) {
                    margin-top: 25px;
                }
            }
            
            &-average {
                max-width: 220px;
                margin-right: 88px;
                
                @include media-breakpoint-down(sm) {
                    margin-right: 37px;
                }
                
                &-wrap {
                    margin-top: 24px;
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;
                    max-width: 86px;
                    flex-wrap: wrap;
                    
                    @include media-breakpoint-down(sm) {
                        max-width: 65px;
                    }
                }
                
                &-circle {
                    width: 18px;
                    height: 18px;
                    background-color: $light-green;
                    border-radius: 50%;
                    margin-right: 16px;
                    
                    @include media-breakpoint-down(sm) {
                        width: 11px;
                        height: 11px;
                        margin-right: 12px;
                    }
                    
                    &:nth-child(3n) {
                        margin-right: 0;
                        margin-bottom: 24px;
                        
                        @include media-breakpoint-down(sm) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            
            &-minutes {
                max-width: 256px;
                
                @include media-breakpoint-down(sm) {
                    margin-top: 150px;
                    max-width: 140px;
                }
                
                p {
                    margin-right: 56px;
                }
                
                &-wrap {
                    width: 100%;
                    margin-top: 24px;
                    display: flex;
                    flex-wrap: wrap;
                }
                
                &-x {
                    width: 18px;
                    height: 18px;
                    position: relative;
                    margin-right: 24px;
                    margin-bottom: 24px;
                    
                    @include media-breakpoint-down(sm) {
                        width: 11px;
                        height: 11px;
                        margin-right: 12px;
                        margin-bottom: 16px;
                    }
                    
                    &.circle {
                        // width: 18px;
                        // height: 18px;
                        background-color: $light-green;
                        border-radius: 50%;
                        
                        
                        &::after, &::before {
                            display: none;
                        }
                    }
                    
                    &::after, &::before {
                        content: '';
                        position: absolute;
                        background-color: $white;
                        height: 2.5px;
                        width: 130%;
                        transform-origin: center;
                        border-radius: 2px;
                    }
                    
                    &::before {
                        top: 50%;
                        left: 0;
                        transform: rotate(45deg) translateY(-50%);
                    }
                    &::after {
                        bottom: 50%;
                        left: 0;
                        transform: rotate(-45deg) translateY(50%);
                    }
                    
                    // &:nth-child(6n) {
                        //     margin-right: 0;
                        // }
                    }
                    
                }
            }
    }
        
    &-buyers {
        margin-top: 160px;
        padding: 0px 37px;
        padding-top: 88px;
        position: relative;
        width: 100%;

        &-wrap {
            @include media-breakpoint-down(md) {
                padding: 0px !important;
            }
        }

        @include media-breakpoint-down(sm) {
            margin-top: 127px;
            padding: 32px 16px;
        }
        
        h2 {
            font-size: 56px;
            line-height: 85px;
            font-weight: 600;
            margin-top: 32px;

            @include media-breakpoint-down(md) {
                margin-bottom: 40px;
            }

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                line-height: 45px;
            }
        }
        
        &::after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            border-radius: 22px;
            height: 100vh;
            background: linear-gradient(329deg, rgba(84, 47, 190, 0) 57.69%, rgba(84, 47, 190, 0.24) 100%);
            opacity: 0.3;
        }
        
        .app-cards-item {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            overflow: hidden;
            
            h4 {
                @include media-breakpoint-down(sm) {
                    line-height: 30px;
                }
            }

            &-img {
                margin-top: 0;
                position: relative;
                
                img {
                    position: relative;
                    top: 60px;
                    width: 110%;

                    @include media-breakpoint-down(sm) {
                        width: 100%;
                    }
                    // width: 100%;
                }
            }
            
            &:nth-child(2n) {
                img {
                    top: 30px;
                    left: -10px;

                    @include media-breakpoint-down(sm) {
                        left: 0;
                    }
                }
            }
            &:first-child {
                img {
                    position: relative;
                    top: 30px;
                }
            }
            
            &:nth-child(5) {
                img {
                    right: -10%;
                }
            }
        }
    }
    
    .app-block {
        &.direct {
            img {
                max-width: 80%;
                
                @include media-breakpoint-down(sm) {
                    max-width: 70%;
                }
            }
        }
        
        &.api {
            .app-block-img {
                position: relative;
                margin-right: -100px;
            }
        }
        
        &.routing {
            .app-block {

                &-text {
                    @include media-breakpoint-down(sm) {
                        margin-top: 32px;
                    }
                }

                &-img {
                    img {
                        position: relative;
                        z-index: 1;
                    }
                    
                    &::after {
                        content: "";
                        width: 560px;
                        height: 560px;
                        border-radius: 50%;
                        position: absolute;
                        top: -50px;
                        left: -140px;
                        background: linear-gradient(180deg, rgba(84, 47, 190, 0) 0%, rgba(84, 47, 190, 0.24) 100%);
                        opacity: 0.3;
                        
                        @include media-breakpoint-down(lg) {
                            width: 50vw;
                            height: 50vw;
                            top: -10vw;
                            left: -20vw;
                        }

                        @include media-breakpoint-down(md) {
                            width: 70vw;
                            height: 70vw;
                            top: -15vw;
                        }
    
                        @include media-breakpoint-down(sm) {
                            width: 90vw;
                            height: 90vw;
                            top: 0;
                            left: -30vw;
                        }
                    }
                }

            }
        }
        
        &.sellers {
            position: relative;
            z-index: 1;
            margin-top: 56px;
            padding-left: 107px;

            @include media-breakpoint-down(lg) {
                padding-left: 0;
            }
            
            .container {
                margin: 0;
                padding: 0;
                width: 100%;
            }
        }
    }
    
    &-sellers {
        width: 100%;
        padding: 86px 37px;
        position: relative;
        z-index: 1;
        width: 100%;

        @include media-breakpoint-down(sm) {
            padding: 40px 16px;
        }
        
        &-wrap {
            @include media-breakpoint-down(md) {
                padding: 0px !important;
            }
        }

        .app-block {
            &-img {
                max-width: 470px;
                position: relative;
                z-index: 1;

                @include media-breakpoint-down(sm) {
                    padding-left: 92px;
                }
                
                &::after {
                    content: "";
                    width: 660px;
                    height: 660px;
                    border-radius: 50%;
                    position: absolute;
                    top: 100px;
                    left: -260px;
                    background: linear-gradient(180deg, rgba(84, 47, 190, 0) 0%, rgba(84, 47, 190, 0.24) 100%);
                    opacity: 0.3;
                    z-index: -1;
                    
                    @include media-breakpoint-down(lg) {
                        width: 80vw;
                        height: 80vw;
                        top: -10vw;
                        left: -50vw;
                    }

                    @include media-breakpoint-down(md) {
                        width: 70vw;
                        height: 70vw;
                        top: 0vw;
                        left: -30vw;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 100vw;
                        height: 100vw;
                        top: -20vw;
                        left: -50vw;
                    }

                    @include media-breakpoint-down(xs) {
                        top: 10vw;
                        left: -10vw;
                    }
                }
            }
        }
        
        h2 {
            font-size: 56px;
            line-height: 84px;
            margin-top: 32px;

            @include media-breakpoint-down(sm) {
                font-size: 30px;
                line-height: 45px;
            }
        }
        
        &::after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            background: linear-gradient(338deg, rgba(219, 250, 96, 0) 28.57%, rgba(219, 250, 96, 0.24) 100%);
            opacity: 0.3;
            border-radius: 22px;
            z-index: -1;
        }
    }
    
    &-cards {
        &-second {
            .app-cards {
                &-item {
                    padding: 40px 52px;
                    overflow: hidden;

                    @include media-breakpoint-down(sm) {
                        padding: 40px 32px;
                    }
                    
                    &-img {
                        margin-top: 45px;
                        
                        img {
                            position: relative;
                        }
                    }
                    
                    &:first-child {
                        img {
                            top: 20px;
                        }
                    }
                    
                    &:nth-child(3) {
                        img {
                            top: 50px;
                            left: 50px;
                        }
                    }
                    
                    &:nth-child(4) {
                        img {
                            top: 20px;
                        }
                    }
                }
            }
        }
    }
}