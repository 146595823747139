.app-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0px;
    position: fixed;
    z-index: 3;
    top: 0;
    left: 0;
    transition: .3s;
    --header-height: 0px;

    @include media-breakpoint-down(md) {
        padding: 24px 0px;
    }

    &.filled {
        background-color: $black-300;
    }

    &.collapsed {
        background-color: $white;
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 0px 0px 1px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 8px 8px;

        .app-header {
            &-logo {
                &-light {
                    display: none;
                }
                &-dark {
                    display: flex;
                }
            }
        }
    }

    &-logo {
        margin-right: 48px;
        display: flex;
        align-items: center;

        &-dark {
            display: none;
        }
    }

    &-nav {
        display: flex;
        align-items: center;
        width: 100%;
        
        @include media-breakpoint-down(md) {
            flex-direction: column;
            
            &.hidden {
                opacity: 0;
                height: 0;
                visibility: hidden;
            }
        }
    }

    &-menu {
        display: flex;
        justify-content: space-between;
        margin-right: auto;
        transition: .8s;

        @include media-breakpoint-down(md) {
            margin-top: 50px;
            width: 100%;
            flex-wrap: wrap;
        }

        li {
            margin-right: 32px;
            cursor: pointer;
            transition: .3s;

            @include media-breakpoint-down(md) {
                width: 50%;
                margin-right: 0;
                margin-bottom: 16px;
            }

            &:hover {
                opacity: .7;
            }

            span {
                font-size: 16px;
                color: $white;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;

                @include media-breakpoint-down(md) {
                    color: $black-300;
                    font-family: 'Inter', sans-serif;
                    justify-content: flex-start;
                }

                img {
                    margin-left: 8px;
                }
            }
        }
        
        &-dropdown {
            transition: .3s;
            transform-origin: center;


            &.active {
                transform: rotate(180deg);
            }
        }

        &-collapsed {
            position: absolute;
            top: 57px;
            left: 0;
            width: 100%;
            padding-top: 60px;
            transition: .3s;
            display: flex;

            @include media-breakpoint-down(md) {
                position: relative;
                top: 0;
                padding-top: 24px;
            }

            .container {
                @include media-breakpoint-down(md) {
                    padding: 0;
                }
            }
            
            &-wrap {
                width: 100%;
                height: auto;
                position: relative;
                padding: 64px 48px;
                background-color: $white;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
                border-radius: 16px;
                display: flex;
                align-items: stretch;
                justify-content: space-between;
                flex-wrap: wrap;

                @include media-breakpoint-up(lg) {
                    overflow-y: auto;
                    max-height: calc(100svh - var(--header-height));
                }
                
                @include media-breakpoint-down(md) {
                    padding: 0;
                    flex-direction: column;
                    background-color: transparent;
                    box-shadow: none;
                }
            }

            &-item {
                // width: calc(50% - 15px);
                width: 50%;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                box-sizing: border-box;
                padding: 0 10px;
                margin-bottom: 30px;

                @include media-breakpoint-down(md) {
                    width: 100%;
                    flex-direction: row;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                &-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;

                    @include media-breakpoint-down(md) {
                        flex-direction: row;
                        align-items: center;
                    }

                    h5 {
                        font-size: 16px;
                        color: $black-100;
                        font-weight: 500;
                        line-height: 24px;
                    }

                    p {
                        margin-top: 4px;
                        font-size: 14px;
                        font-weight: 400;
                        color: $black-200;

                        @include media-breakpoint-down(md) {
                            display: none;
                        }
                    }
                }

                &-icon {
                    width: 48px;
                    height: 48px;
                    margin-bottom: 16px;
                    flex-shrink: 0;

                    @include media-breakpoint-down(md) {
                        margin-right: 16px;
                        margin-bottom: 0;
                    }

                    @include media-breakpoint-down(sm) {
                        width: 38px;
                        height: 38px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                &-link {
                    margin-top: auto;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 20px;
                    color: $black-100;
                    display: flex;
                    align-items: center;

                    @include media-breakpoint-down(md) {
                        display: none;
                    }

                    &:hover {
                        img {
                            margin-left: 8px;
                        }
                    }

                    img {
                        max-height: 20px;
                        height: 100%;
                        transition: .3s;
                        width: auto;
                        margin-left: 4px;
                    }
                }

                a:not(.app-header-menu-collapsed-item-link) {
                    margin-bottom: 15px;

                    @include media-breakpoint-down(md) {
                        margin-bottom: 0;
                    }
                }
            }

            &.hidden {
                opacity: 0;
                z-index: -10;
                visibility: hidden;
            }
        }
    }

    &-toggler {
        display: none;
        flex-direction: column;
        align-items: stretch;
        justify-content: space-between;
        width: 24px;
        height: 20px;
        position: relative;
        cursor: pointer;

        @include media-breakpoint-down(md) {
            display: flex;
        }

        &.active {
            .app-header-toggler {
                &-line {
                    background-color: $black-100;

                    &.one {
                        top: 50%;
                        transform: translateY(-50%) rotate(45deg)
                    }
                    &.two {
                        opacity: 0;
                    }
                    &.three {
                        top: -50%;
                        transform: translateY(50%) rotate(-45deg);
                    }
                }
            }
        }

        &-line {
            // position: absolute;
            background-color: $white;
            width: 24px;
            height: 2px;
            transition: .3s;
            position: relative;
            // top: 50%;
            border-radius: 2px;
            transform-origin: center;

            // &.one {
                // top: 0;
                // transform: translateY(0px);
            // }
            // &.three {
                // bottom: 0;
                // transform: translateY(0px);
            // }
        }
    }

    &-btns {
        display: flex;

        @include media-breakpoint-down(md) {
            flex-direction: column;
            width: 100%;
            margin-top: 24px;
        }

        a {
            margin-left: 16px;
            width: 124px;

            @include media-breakpoint-down(md) {
                margin-left: 0;
                width: 100%;
                margin-bottom: 10px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .container {
        align-items: center;
        flex-wrap: nowrap;

        @include media-breakpoint-down(md) {
            flex-wrap: wrap;
        }
    }
}
  
