.app-section.dive-into {
    padding: 283px 0px 221px;
    position: relative;

    @include media-breakpoint-down(sm) {
        padding: 155px 0px 180px;
    }

    // Background Perspective Grid
    &::before, &::after {
        content: "";
        width: 100%;
        height: desktop_vw_max(440px);
        position: absolute;
        left: 0;
        bottom: 0;
        background-image: url(../../../assets/images/grid-perspective.svg);
        background-size: auto;
        background-repeat: no-repeat;
        background-position: bottom;
        z-index: -1;
        
        @include media-breakpoint-down(sm) {
            height: desktop_vw_max(220px);
            background-size: cover;
        }
    }

    &::before {
        transform: scale(1, -1);
        bottom: auto;
        top: 0;
    }

    h2 {
        text-align: center;
        font-size: 48px;
        font-weight: 700;
        color: map-get($teleanalytixai-colors, 'gray-900' );

        @include media-breakpoint-down(sm) {
            font-size: 36px;
            line-height: 140%;
            text-align: left;
        }

        br {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        span {
            color: map-get($teleanalytixai-colors, 'purple-700' );
        }
        
        img {
            vertical-align: top;
            max-width: 360px;
            margin-top: 0.75rem;

            @include media-breakpoint-down(sm) {
                max-width: 340px;
            }
        }
    }

    .dive-into {
        &__wrap {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1;

            &::before {
                content: "";
                position: absolute;
                top: 20%;
                left: 50%;
                transform: translateX(-50%);

                @include haze-effect();
            }
        }
    } 

    .app-button {
        position: relative;
    
        @include media-breakpoint-down(sm) {
            margin-top: 40px;
        }
    } 

    .teleanalytix-chat {
        margin-top: 100px;

        &__main {
            position: relative;
            padding-top: 216px;

            @include media-breakpoint-down(sm) {
                padding-top: 363px;
            }
        }

        &__message {        
            &-text {
                @include media-breakpoint-up(sm) {
                    position: relative;
                }
            }
        }

        .archer-line {
            position: absolute;
            right: -121px;
            bottom: -155px;
            z-index: 1;
            width: 260%;       
            pointer-events: none; 

            @include media-breakpoint-down(sm) {
                bottom: auto;
                width: calc(max(80%, 370px));
                right: auto;
                left: -23px;
                top: 42px;
            }
            
            img {
                width: 100%;
                height: auto;
            }
        }
    }
}