.app-teleanalytixai {
    --container-max-width: 1536px;
    --container-padding-xl: 140px;

    main {
        .app-button {
            border-radius: 14px;
            font-size: 20px;
            font-weight: 500;
            line-height: 140%;
            max-width: 265px;
            width: 100%;
            height: auto;
            display: inline-flex;
            padding: 14px 24px;
    
            &-icon {
                width: 32px;
                height: 32px;
                order: -1;
                margin-left: 0;
                margin-right: 8px;
            }
    
            &:hover {
                .app-button-icon {
                    margin-left: 0;
                }
            }
    
            &.green {
                padding: 24px;
                font-size: 30px;
                font-weight: 600;
                color: map-get($teleanalytixai-colors, 'lime-950');
                border: 2px solid map-get($teleanalytixai-colors, 'green-300');                
            
                &:hover {
                    opacity: 1;
                    background-color: map-get($teleanalytixai-colors, 'green-400' );
                }

                &:active {
                    opacity: 0.7;
                }
            }
        }
    }
}