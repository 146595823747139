$sizes: (
    'lg': '1200',
    'md': '992',
    'sm': '768',
    'xs': '576'
);

@mixin media-breakpoint-down($breakpoint) {
    $prop: map-get($sizes, $breakpoint);
        
    @media screen and (max-width: #{$prop}px) {
        @content;
    }
}

@mixin media-breakpoint-up($breakpoint) {
    $prop: map-get($sizes, $breakpoint);
        
    @media screen and (min-width: #{$prop}px) {
        @content;
    }
}

@function desktop_vw($px) { 
    $vw_value: ($px / 1440px) * 100 * 1vw;
    @return calc(#{$vw_value});
}

@function desktop_vw_min($px) {
    $vw_value: ($px / 1440px) * 100 * 1vw;
    @return calc(min(#{$vw_value}, #{$px}));
}

@function desktop_vw_max($px) {
    $vw_value: ($px / 1440px) * 100 * 1vw;
    @return calc(max(#{$vw_value}, #{$px}));
}

@mixin grid-effect() {
    width: desktop_vw_min(850px);
    height: desktop_vw_min(700px);
    background-image: url(../../../assets/images/grid.svg);
    background-repeat: no-repeat;
    background-size: contain;
    pointer-events: none;
    
    @include media-breakpoint-down(sm) {
        width: 850px;
        height: 700px;
    }
}

@mixin haze-effect($color : 'purple') {
    width: 536px;
    height: 536px;
    background-image: url(../../../assets/images/haze-#{$color}.svg);
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
}