@import url('https://fonts.googleapis.com/css2?family=Inter:wght@500&family=Poppins:wght@300;400;500;600;800&display=swap');
@import "./styles/__colors";
@import './styles/__helpers';
@import './styles/_spacing';
@import './styles/__app';
@import './styles/templates';

html, #root {
  height: auto;
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;

  --container-max-width: 1440px;
  --container-padding-xl: 64px;
  --container-padding-lg: 64px;
  --container-padding-md: 64px;
  --container-padding-sm: 16px;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  outline: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  color: $black-100;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  color: $black-100;
  text-decoration: none;
  transition: .3s;

  &:hover {
    opacity: .7;
  }
}

p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;

  @include media-breakpoint-down(md) {
    font-size: 14px;
    line-height: 21px;
  }
}

img {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
}

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  box-sizing: border-box;
  margin: 0 auto;
  
  padding-left: var(--container-padding-xl);
  padding-right: var(--container-padding-xl);
  max-width: var(--container-max-width);

  @include media-breakpoint-down(lg) {
    padding-right: var(--container-padding-lg);
    padding-left: var(--container-padding-lg);
  }
  
  @include media-breakpoint-down(md) {
    padding-right: var(--container-padding-md);
    padding-left: var(--container-padding-md);
  }
  
  @include media-breakpoint-down(sm) {
    padding-left: var(--container-padding-sm);
    padding-right: var(--container-padding-sm);
  }
}


// ############ Slider ############ 

.slick-slide:focus {
  outline: none;
}